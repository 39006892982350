import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editButton", "saveButton", "viewMode", "editMode", "form"]
    static values = { editState: Boolean }

    connect() {
        this.editStateValue = this.element.dataset.formEditStateEditStateValue === 'true'
        this.updateUI()
        if (this.hasFormTarget) {
            this.formTarget.addEventListener("keypress", this.preventEnterSubmit.bind(this))
        }
    }

    disconnect() {
        if (this.hasFormTarget) {
            this.formTarget.removeEventListener("keypress", this.preventEnterSubmit.bind(this))
        }
    }

    toggleEditMode(event) {
        event.preventDefault()
        if (this.editStateValue) {
            // If currently in edit mode, treat this as a cancel action
            this.cancelEdit()
        } else {
            this.editStateValue = true
            this.updateUI()
        }
    }

    updateUI() {
        this.viewModeTargets.forEach(el => el.classList.toggle("hidden", this.editStateValue))
        this.editModeTargets.forEach(el => el.classList.toggle("hidden", !this.editStateValue))
        this.saveButtonTarget.classList.toggle("hidden", !this.editStateValue)
        this.editButtonTarget.textContent = this.editStateValue ? "Cancel" : "Edit"
    }

    handleSubmit(event) {
        const fetchResponse = event.detail.fetchResponse

        if (fetchResponse.response.ok) {
            // Successful update
            this.editStateValue = false
            // The view will be updated by Turbo Streams
        } else {
            // If there are errors, keep the form in edit mode
            this.editStateValue = true
            // The form with errors will be replaced by Turbo Streams
        }

        this.updateUI()
    }

    preventEnterSubmit(event) {
        if (event.key === "Enter" &&
            event.target.tagName !== "TEXTAREA" &&
            !event.target.closest('trix-editor')) {
            event.preventDefault()
        }
    }

    cancelEdit() {
        if (this.hasFormTarget) {
            const cancelInput = document.createElement('input')
            cancelInput.type = 'hidden'
            cancelInput.name = 'cancel'
            cancelInput.value = 'true'
            this.formTarget.appendChild(cancelInput)

            // Submit the form
            this.formTarget.requestSubmit()

            // Remove the cancel input after submission
            this.formTarget.removeChild(cancelInput)
        }
    }

    handleFileSelected(event) {
        const file = event.target.files[0];
        if (file) {
            // You might want to show a preview here
            console.log(`File selected: ${file.name}`);
        }
    }
}